import styled from "styled-components";
import { Header } from "../../modal/style";

export const ModalHeader = styled(Header)`
`;

export const ModalContainer = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
`;

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ChoosePaymentMethodButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  cursor: pointer;
`;

export const PaymentMethodNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PaymentMethodImage = styled.div`
  width: 35px;
  height: 24px;
  margin-bottom: 3px;
`;

export const PaymentMethodText = styled.span`
  margin-left: 6px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const PaymentMethodRadio = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: solid 1px ${({ theme: { colors } }): string => colors.darkGrey};

  @media (min-width: 1200px) {
    margin-right: 7px;
  }
`;

export const PaymentMethodRadioSelected = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
`;

export const AddPaymentMethodButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  cursor: pointer;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;
