import styled from "styled-components";

export const CheckboxWrapper = styled.div`
  min-width: 280px;
`;

export const CheckboxLabel = styled.label`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  color: ${({ theme: { colors } }): string => colors.primary};
  font-size: 15px;
  cursor: pointer;
  width: 100%;
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;
