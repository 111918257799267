import styled, { keyframes } from "styled-components";
import Button from "../../../ui/button";
import OAddressButton from "../../shopping-cart/address-button";

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
`;

interface Props {
  isFirstChild?: boolean;
}

export const SummaryLineContainer = styled.div<Props>`
  margin-top: ${({ isFirstChild }): number => (!isFirstChild ? 14 : 0)}px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContentContainer = styled.div`
  margin-top: 16px;
  padding: 22px;
  width: 90%;

  @media (min-width: 700px) {
    width: 50%;
  }
`;

export const Title = styled.span`
  margin-bottom: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
`;

export const AddressButton = styled(OAddressButton)`
  margin-bottom: 20px;
`;

export const SummaryButton = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.white};
  height: 48px;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-horizontal: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const OptionnalInfoButton = styled.button`
  background: none;
  border: none;
  color: ${({ theme: { colors } }): string => colors.secondary};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  margin-bottom: 4px;
  padding: 0;

  svg {
    fill: ${({ theme: { colors } }): string => colors.secondary};
    margin-right: 10px;
    height: 1.5em;
    width: 1.5em;
  }
`;

const displayOptionnalInfo = keyframes`
  0%{height:0}
  100%{height:72px}
`;

export const OptionnalInfoContainer = styled.div`
  animation: ${displayOptionnalInfo} 2s ease-out;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
`;

export const MoreInfoInput = styled.textarea`
  border: none;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const OrderButton = styled(Button)`
  margin-top: 20px;
  height: 48px;
  object-fit: contain;
  border-radius: 57px;
  width: 286px;
  color: ${({ theme: { colors } }): string => colors.white};
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  margin-bottom: 19px;
`;

export const InputDark = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
  margin-left: 15px;
`;
export const H3Dark = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.primary};
  font-weight: bold;
`;

export const InputSecondary = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  color: ${({ theme: { colors } }): string => colors.secondary};
  margin-right: 15px;
`;

export const CheckboxWrapper = styled.div`
  margin: 10px;
`;
