import React, { FC } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import AddPayment from "../payment";
import { Modal } from "../modal";
import { Header } from "../modal/style";
import { FormattedMessage } from "react-intl";
import { PaymentMethod } from "../../types/payment-method";

interface AddPaymentModalProps {
  closeModal: () => void;
  onSuccess: (paymentMethod: PaymentMethod) => void;
  open: boolean;
}

const AddPaymentModal: FC<AddPaymentModalProps> = ({
  closeModal,
  onSuccess,
  open,
}) => (
  <Modal open={open} closeModal={closeModal}>
    <Header>
      <FormattedMessage id="profile.add-payment.add" />
    </Header>
    {typeof window !== "undefined" ? (
      <StripeProvider apiKey={`${process.env.GATSBY_STRIPE_API_KEY_PUBLIC}`}>
        <Elements>
          <AddPayment onSuccess={onSuccess} inModal />
        </Elements>
      </StripeProvider>
    ) : (
      <FormattedMessage id="error.couldNotLoadStripeElements" />
    )}
  </Modal>
);

export default AddPaymentModal;
