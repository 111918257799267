import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Modal } from "../modal";
import {
  AddPaymentMethodButton,
  ModalContainer,
  ModalContentContainer,
  ModalHeader,
  PaymentMethodText,
  SpinnerContainer
} from "./style";
import Spinner from "../../images/spinner.svg";
import AddIcon from "../../images/add.svg";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import { AppState } from "../../store";
import { PaymentMethod } from "../../types/payment-method";
import { getPaymentMethods } from "../../store/user/actions";
import ChoosePaymentMethodButton from "./ChoosePaymentMethodButton";

interface ChoosePaymentMethodModalProps {
  addNewPayment: () => void;
  closeModal: () => void;
  onPaymentSelection: (paymentMethod: PaymentMethod) => void;
  open: boolean;
}

const ChoosePaymentMethodModal: FC<ChoosePaymentMethodModalProps> = ({
  addNewPayment,
  closeModal,
  onPaymentSelection,
  open,
}) => {
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);
  const paymentMethods = useSelector<AppState, PaymentMethod[]>(
    state => state.user.paymentMethods
  );

  const selectedPaymentMethod = useSelector<
    AppState,
    PaymentMethod | undefined
  >(state => state.shoppingCart.paymentMethod);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      const response = await dispatch(getPaymentMethods());
      if (!response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR,
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, showToast, open]);

  return (
    <Modal closeModal={closeModal} open={open} >
      <ModalContainer>
        <ModalHeader>
          <FormattedMessage id="profile.add-payment.choose" />
        </ModalHeader>
        <ModalContentContainer>
          {loading ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <>
              {paymentMethods &&
                paymentMethods.map(paymentMethod => (
                  <ChoosePaymentMethodButton
                    {...paymentMethod}
                    key={paymentMethod.id}
                    onPaymentSelection={onPaymentSelection}
                    selected={
                      selectedPaymentMethod !== undefined &&
                      selectedPaymentMethod.id === paymentMethod.id
                    }
                  />
                ))}
              <AddPaymentMethodButton onClick={addNewPayment}>
                <AddIcon />
                <PaymentMethodText>
                  <FormattedMessage id="addPaymentMethod" />
                </PaymentMethodText>
              </AddPaymentMethodButton>
            </>
          )}
        </ModalContentContainer>
      </ModalContainer>
    </Modal>
  );
};

export default ChoosePaymentMethodModal;
