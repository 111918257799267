import React, { FC } from "react";
import { PaymentMethod } from "../../types/payment-method";
import PaymentMethodIcon from "../payment-methods/payment-method-icon";
import getCardNumber from "../../utils/cardNumber";

import {
  ChoosePaymentMethodButtonContainer,
  PaymentMethodImage,
  PaymentMethodNameContainer,
  PaymentMethodRadio,
  PaymentMethodRadioSelected,
  PaymentMethodText,
} from "./style";

interface ChoosePaymentMethodButtonPros extends PaymentMethod {
  onPaymentSelection: (paymentMethod: PaymentMethod) => void;
  selected: boolean;
}

const ChoosePaymentMethodButton: FC<ChoosePaymentMethodButtonPros> = ({
  selected,
  onPaymentSelection,
  ...paymentMethod
}) => {
  const { brand, last4 } = paymentMethod;

  return (
    <ChoosePaymentMethodButtonContainer onClick={(): void => onPaymentSelection(paymentMethod)}>
      <PaymentMethodNameContainer>
        <PaymentMethodImage>
          <PaymentMethodIcon brand={brand} />
        </PaymentMethodImage>
        <PaymentMethodText>{getCardNumber(last4)}</PaymentMethodText>
      </PaymentMethodNameContainer>
      <PaymentMethodRadio>
        {selected && <PaymentMethodRadioSelected />}
      </PaymentMethodRadio>
    </ChoosePaymentMethodButtonContainer>
  );
};

export default ChoosePaymentMethodButton;
