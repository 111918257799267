import React, { FC } from "react";

import { CheckboxWrapper, Checkbox, CheckboxLabel } from "./style";

interface CheckboxProps {
  id: string;
  labelContent: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
}

const CheckboxComponent: FC<CheckboxProps> = ({
  id,
  labelContent,
  onChange,
  name,
}) => {
  return (
    <CheckboxWrapper>
      <Checkbox
        onChange={onChange}
        type="checkbox"
        id={id}
        name={name ? name : id}
      />
      <CheckboxLabel htmlFor={id}>{labelContent}</CheckboxLabel>
    </CheckboxWrapper>
  );
};

export default CheckboxComponent;
