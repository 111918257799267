import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Order from "../components/order-recap";

const IndexPage: FC = () => (
  <Layout
    header={false}
    footer={false}
    menuState="cart"
    progressbar={true}
    requireAuth
    progressBarStep={3}
  >
    <SEO title="Commande" />
    <Order />
  </Layout>
);

export default IndexPage;
