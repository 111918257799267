import React, { FC, useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { FormattedMessage, FormattedHTMLMessage, useIntl } from "react-intl";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import Checkbox from "../../ui/checkbox";
import {
  AddressButton,
  ButtonContainer,
  CheckboxWrapper,
  Container,
  ContentContainer,
  H3Dark,
  InputDark,
  InputSecondary,
  MoreInfoInput,
  OptionnalInfoButton,
  OptionnalInfoContainer,
  OrderButton,
  SummaryButton,
  SummaryLineContainer,
  Title,
} from "./style";
import ChoosePaymentMethodModal from "../choose-payment-method-modal";
import { get, HttpResponse } from "../../utils/http";
import { useTextArea } from "../../ui/input";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import { AppState } from "../../store";
import { Range } from "../../types/range";
import { PaymentMethod } from "../../types/payment-method";
import { sendOrder, setPaymentMethod } from "../../store/shopping-cart/actions";
import twoDecimals from "../../utils/twoDecimals";
import getCardNumber from "../../utils/cardNumber";
import AddPaymentModal from "../add-payment-modal";
import EditIcon from "../../images/edit.svg";
import userLanguage from "../hooks/useLanguage";

interface ParsedBody {
  id: string;
}

const OrderRecap: FC = () => {
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const intl = useIntl();
  const locale = userLanguage();

  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState<boolean>(false);
  const [isAddPaymentModalOpen, openAddPaymentModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tosAreChecked, setTosCheck] = useState<boolean>(false);
  const [isOptionnalInfoVisible, displayOptionnalInfo] = useState<boolean>(false);

  const pickupRange = useSelector<AppState, Range | undefined>(state => state.shoppingCart.pickupRange);
  const dropOffRange = useSelector<AppState, Range | undefined>(state => state.shoppingCart.dropOffRange);
  const paymentMethod = useSelector<AppState, PaymentMethod | undefined>(state => state.shoppingCart.paymentMethod);
  const totalPrice = useSelector<AppState, number>(({ shoppingCart: { totalPrice } }) => totalPrice);
  const shoppingCartValid = useSelector<AppState, boolean>(state => state.shoppingCart.valid);
  const manuallyAdded = useSelector<AppState, boolean>(state => state.shoppingCart.addressManuallyAdded);
  const state = useSelector<AppState, AppState>(state => state);

  const inputPropsQuestion = useTextArea();
  const inputOptionalAddressNote = useTextArea();

  const goToShoppingCart = useCallback(() => navigate("/cart"), [navigate]);

  const togglePaymentModal = useCallback(() => {
    setIsPaymentModalVisible(!isPaymentModalVisible);
  }, [isPaymentModalVisible]);

  const handleOrderPress = useCallback(async () => {
    setLoading(true);

    if (!paymentMethod) {
      showToast(
        intl.formatMessage({ id: "error.paymentMethod.mustBeSelected" }),
        {
          type: TOAST_TYPE.ERROR,
        }
      );
      setLoading(false);

      return;
    }

    if (!shoppingCartValid) {
      showToast(intl.formatMessage({ id: "notValid" }), {
        type: TOAST_TYPE.ERROR,
      });
      setLoading(false);

      return;
    }

    if (!tosAreChecked) {
      showToast(intl.formatMessage({ id: "error.cgu.mustBeChecked" }), {
        type: TOAST_TYPE.ERROR,
      });
      setLoading(false);

      return;
    }

    const response = await dispatch(
      sendOrder(inputPropsQuestion.value, inputOptionalAddressNote.value)
    );
    if (response.ok) {
      const { id } = response.parsedBody as ParsedBody;
      navigate(`/order-confirmation?order=${id}`);
    } else {
      showToast(response.error ? response.error.message : "Error", {
        type: TOAST_TYPE.ERROR,
      });
    }

    setLoading(false);
  }, [
    dispatch,
    inputPropsQuestion.value,
    navigate,
    showToast,
    shoppingCartValid,
    intl,
    tosAreChecked,
  ]);

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      let response!: HttpResponse<PaymentMethod>;
      try {
        setLoading(true);
        response = await get(`/payment-methods/default`, state);
        if (response.parsedBody && Object.entries(response.parsedBody).length) {
          dispatch(setPaymentMethod(response.parsedBody));
        }
      } catch (error) {
        console.error("error", error);
        return error;
      }
      setLoading(false);
    };
    
    fetchData();
  }, [dispatch, state.auth.token]);

  const addNewPaymentMethod = (paymentMethod: PaymentMethod): void => {
    dispatch(setPaymentMethod(paymentMethod));
    openAddPaymentModal(false);
    setIsPaymentModalVisible(false)
    showToast(
      intl.formatMessage({ id: "profile.add-payment.success" }),
      { type: TOAST_TYPE.SUCCESS }
    );
  };

  return (
    <>
      <Container>
        <ContentContainer>
          <Title>
            <FormattedMessage id="deliveryAddress" />
          </Title>
          <AddressButton />
          {manuallyAdded ? (
            <>
              <Title>
                <FormattedMessage id="addressnotetitle" />
              </Title>
              <MoreInfoInput
                placeholder={intl.formatMessage({ id: "noteexemple" })}
                {...inputOptionalAddressNote}
              />
            </>
          ) : null}
          <Title>
            <FormattedMessage id="cart.shippingTime.pickup" />
          </Title>
          <SummaryButton onClick={goToShoppingCart}>
            <InputDark>
              {pickupRange
                ? `${dayjs(pickupRange.startDate)
                    .locale(locale)
                    .format("DD MMM YYYY")}, ${dayjs(
                    pickupRange.startDate
                  ).format("HH:mm")} - ${dayjs(pickupRange.endDate).format(
                    "HH:mm"
                  )}`
                : ""}
            </InputDark>
            <InputSecondary>
              <FormattedMessage id="cart.change" />
            </InputSecondary>
          </SummaryButton>
          <Title>
            <FormattedMessage id="shipping" />
          </Title>
          <SummaryButton onClick={goToShoppingCart}>
            <InputDark>
              {dropOffRange
                ? `${dayjs(dropOffRange.startDate)
                    .locale(locale)
                    .format("DD MMM YYYY")}, ${dayjs(
                    dropOffRange.startDate
                  ).format("HH:mm")} - ${dayjs(dropOffRange.endDate).format(
                    "HH:mm"
                  )}`
                : ""}
            </InputDark>
            <InputSecondary>
              <FormattedMessage id="cart.change" />
            </InputSecondary>
          </SummaryButton>
          <Title>
            <FormattedMessage id="payment" />
          </Title>
          <SummaryButton onClick={togglePaymentModal}>
            <InputDark>
              {paymentMethod
                ? getCardNumber(paymentMethod.last4)
                : intl.formatMessage({ id: "noCard" })}
            </InputDark>
            <InputSecondary>
              <FormattedMessage id="cart.change" />
            </InputSecondary>
          </SummaryButton>
          <OptionnalInfoButton onClick={(): void => displayOptionnalInfo(true)}>
            <EditIcon />
            <FormattedMessage id="optionnalInfo" />
            </OptionnalInfoButton>
          { isOptionnalInfoVisible
            ? (
              <OptionnalInfoContainer>
                <MoreInfoInput
                  placeholder={intl.formatMessage({ id: "doyouhaverequest" })}
                  {...inputPropsQuestion}
                />
              </OptionnalInfoContainer>
            ) : null
          }
          <SummaryLineContainer>
            <InputDark>
              <FormattedMessage id="total" />
            </InputDark>
            <H3Dark>{twoDecimals(totalPrice / 100)}€</H3Dark>
          </SummaryLineContainer>
          <CheckboxWrapper>
            <Checkbox
              id="check-tos"
              labelContent={<FormattedHTMLMessage id="cgu.checkbox" />}
              onChange={(e): void => setTosCheck(e.currentTarget.checked)}
            />
          </CheckboxWrapper>
          <ButtonContainer>
            <OrderButton onClick={handleOrderPress} loading={loading}>
              {intl.formatMessage({ id: "order" })}
            </OrderButton>
          </ButtonContainer>
        </ContentContainer>
      </Container>
      <ChoosePaymentMethodModal
        addNewPayment={(): void => {
          setIsPaymentModalVisible(false);
          openAddPaymentModal(true);
        }}
        closeModal={togglePaymentModal}
        onPaymentSelection={addNewPaymentMethod}
        open={isPaymentModalVisible}
      />
      <AddPaymentModal
        closeModal={(): void => openAddPaymentModal(false)}
        onSuccess={addNewPaymentMethod}
        open={isAddPaymentModalOpen}
      />
    </>
  );
};

export default OrderRecap;
